<template>
<div>
    <section id="knowledge-base-content">
        <app-collapse type="margin">
            <app-collapse-item :title="element.title+' Ayarları'" v-for="element in menu" :key="element.uid">
                <b-form class="p-2" @submit.prevent>
                    <!-- Code -->
                    <b-form-group :label="input.text" label-for="from" v-for="input in element.props" :key="input.uid">
                        <b-form-input id="from" type="text" v-model="input.value" v-if="input.type=='text'" />
                        <v-select id="vue-select" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text" :reduce="(values) => values.value" :options="input.values" v-model="input.value" v-if="input.type=='select'" />
                    </b-form-group>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button variant="primary" style="width:100%" type="submit" @click="setData(element)">
                            Güncelle
                        </b-button>

                    </div>
                </b-form>
                <div class="text-center">
                    <span v-html="element.helpText"></span>
                </div>
            </app-collapse-item>
        </app-collapse>
    </section>
</div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BCardText,
    BInputGroupPrepend,
    BSidebar,
    BListGroupItem
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
export default {
    components: {
        vSelect,
        AppCollapse,
        AppCollapseItem,
        ToastificationContent,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BCardBody,
        BCardText,
        BForm,
        BInputGroup,
        BInputGroupPrepend,
        BFormInput,
        BSidebar,
        BListGroupItem,
        BButton
    },
    data() {
        return {
            menu: [{
                    title: 'Zoom Entegrasyon',
                    helpText: 'Öğrencilerin canlı derslere katılabilmesi,canlı derslerin oluşturulabilmesi için zoom entegrasonu yapılması gerekir!<br>Zoom entegrasyonu için;<br>Api Key ve Api Secret Keyi girin',
                    uid: 'zoom-integration',
                    dataUrl: 'Branch/ZoomIntegration',
                    insertUrl: 'Branch/AddZoomIntegration',
                    props: [{
                            text: 'Api Key',
                            value: '',
                            uid: 'apiKey',
                            type: 'text'
                        },
                        {
                            text: 'Api Secret',
                            value: '',
                            uid: 'apiSecret',
                            type: 'text'
                        }
                    ]
                },
                {
                    title: 'Tekrar İzle Entegrasyon',
                    helpText: 'Size gönderilen Tekrar İzle Kullanıcı adı ve şifresini girin',
                    uid: 'kaltura-integration',
                    dataUrl: 'Branch/KalturaIntegration',
                    insertUrl: 'Branch/AddKalturaIntegration',
                    props: [{
                            text: 'Kullanıcı Adı',
                            value: '',
                            uid: 'loginId',
                            type: 'text'
                        },
                        {
                            text: 'Şifre',
                            value: '',
                            uid: 'password',
                            type: 'text'
                        }
                    ]
                },
                {
                    title: 'Sms Entegrasyon',
                    helpText: 'Öğrencilerinize sınav sonuçları,ev ödevleri gibi bildirimlerin sms olarak gidebilmesi için <br>Sms Api Entegrasyon için;<br>Kullanıcı Adı,Şifre ve Sms Api Tipi.',
                    uid: 'sms-integration',
                    dataUrl: 'Branch/SmsIntegration',
                    insertUrl: 'Branch/AddSmsIntegration',
                    props: [{
                            text: 'Kullanıcı Adı',
                            value: '',
                            uid: 'loginId',
                            type: 'text'
                        },
                        {
                            text: 'Şifre',
                            value: '',
                            uid: 'password',
                            type: 'text'
                        },
                        {
                            text: 'Organizasyon Adı',
                            value: '',
                            uid: 'organization',
                            type: 'text'
                        },
                        {
                            text: 'Api Tip',
                            value: '',
                            uid: 'type',
                            type: 'select',
                            values: [{
                                    text: 'Mutlu Cell Api',
                                    value: 'MutluCell'
                                },
                                {
                                    text: 'Vatan Api',
                                    value: 'Vatan'
                                },
                                {
                                    text: 'İleti Merkezi Api',
                                    value: 'IletiMerkezi'
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    },
    created() {
        this.menu.forEach(async menu => {
            if (menu.dataUrl != '') {
                var respData = await this.$http.get(menu.dataUrl);
                var data = respData.data.data;
                menu.props.forEach(prop => {
                    prop.value = data[prop.uid];
                });
            }
        });
    },
    methods: {
        async setData(item) {
            var requestUrl = item.insertUrl;
            var request = {};
            item.props.forEach(prop => {
                request[prop.uid] = prop.value;
            });
            await this.$http.post(requestUrl, request);
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";
@import '@core/scss/vue/pages/page-knowledge-base.scss';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
